import styled, { css } from 'styled-components';

type valueProps = {
  $isPositive: boolean;
};

export const PercentageChangeContainer = styled.div<valueProps>`
  ${({ theme, $isPositive = true }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    width: 50px;
    height: ${theme.spacing['16']};
    border-radius: 36px;
    gap: ${theme.spacing['08']};
    background-color: ${$isPositive
      ? theme.colors.success['100']
      : theme.colors.error['100']};
    color: ${$isPositive
      ? theme.colors.success['700']
      : theme.colors.error['700']};
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.spacing['08']};
    max-height: ${theme.spacing['08']};
    margin-right: ${theme.spacing['08']};
    border-radius: ${theme.radius.circle};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${theme.typography.fontSize['18']}.size;
    line-height: ${theme.typography.fontSize['18'].lineHeight};
  `}
`;

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: ${theme.spacing[48]};
  `}

  & .positiveIcon {
    ${({ theme }) => css`
      max-width: ${theme.spacing['16']};
      max-height: ${theme.spacing['16']};
      padding-left: ${theme.spacing['04']};
    `}
  }

  & .negativeIcon {
    ${({ theme }) => css`
      max-width: ${theme.spacing['16']};
      max-height: ${theme.spacing['16']};
      padding-left: ${theme.spacing['04']};
    `}
  }
`;
export const Text = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    font-size: ${theme.typography.fontSize['12'].size};
    font-weight: ${theme.typography.fontWeight.semiBold};
  `}
`;

export const PositiveIcon = styled(Icon)`
  ${({ theme }) => css`
    background-color: ${theme.colors.success['100']};
    color: ${theme.colors.success['700']};
    max-width: ${theme.spacing['08']};
    max-height: ${theme.spacing['08']};
  `}
`;

export const NegativeIcon = styled(Icon)`
  ${({ theme }) => css`
    background-color: ${theme.colors.error['100']};
    color: ${theme.colors.error['700']};
    width: ${theme.spacing['08']};
    height: ${theme.spacing['08']};
  `}
`;
