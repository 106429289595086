import Cookies from 'js-cookie';
export interface RequestParams {
  url: string;
  headers?: Record<string, string>;
  method?: keyof typeof MethodProps;
  body?: Record<string, unknown> | string;
  cache?: RequestCache;
}

export enum MethodProps {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface RequestParamsErrorResponseProps {
  message: string;
  code: number;
  causes?: string;
}

const buildCustomHeaders = () => {
  const userId = Cookies.get('userId') || '';
  const token = Cookies.get('token') || '';
  const projectId = Cookies.get('projectId') || '';
  const organizationId = Cookies.get('organizationId') || '';

  return {
    'Content-Type': 'application/json',
    Authorization: token,
    'X-User-Id': userId,
    ProjectId: projectId || '',
    OrganizationId: organizationId || '',
  };
};

export async function request<T>(
  params: RequestParams
): Promise<T | RequestParamsErrorResponseProps> {
  const { url, method = 'GET', body, headers, cache } = params;
  const customHeaders = buildCustomHeaders();

  try {
    const response = await fetch(url, {
      headers: {
        ...customHeaders,
        ...headers,
      },
      method,
      body: JSON.stringify(body),
      cache,
    });
    if (response.ok) {
      if (response.status === 201) {
        try {
          const data = await response.json();
          return data;
        } catch (jsonError) {
          return { message: 'Created', code: response.status };
        }
      } else if (response.status === 204) {
        return { message: 'OK', code: response.status };
      } else {
        try {
          const data = await response.json();
          return data;
        } catch (jsonError) {
          return { message: 'Deleted', code: response.status };
        }
      }
    } else {
      const data = await response.json();
      return Promise.reject({ ...data, code: response.status });
    }
  } catch (error) {
    return Promise.reject({
      message: error,
      code: 500,
    });
  }
}
