'use client';
import * as S from './styles';

interface TipsProps {
  type: 'info' | 'warning' | 'error' | 'success' | 'tip' | 'error-warning';
  message: string | React.ReactNode;
}

const Tips = ({ type = 'success', message }: TipsProps) => {
  let label = '';
  switch (type) {
    case 'tip':
      label = 'Dica';
      break;
    case 'info':
      label = 'Informação';
      break;
    case 'warning':
    case 'error-warning':
      label = 'Atenção';
      break;
    case 'error':
      label = 'Erro';
      break;
    default:
      label = 'Sucesso';
      break;
  }

  return (
    <S.Tips type={type}>
      <span></span>
      <label>
        <span>{label}: </span>
        {message}
      </label>
    </S.Tips>
  );
};

export default Tips;
