import { Cancel, CheckCircle, Error, Info } from '@mui/icons-material';
import * as Toast from '@radix-ui/react-toast';
import styled, { css } from 'styled-components';

export const ToastRoot = styled(Toast.Root)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing['08']};
    border-radius: ${theme.radius.xs};
    box-shadow: ${theme.shadows.xl};
    padding: ${theme.spacing['12']} ${theme.spacing['16']};
    min-width: 256px;
    background-color: ${theme.newColors.surfaceSecondarySurfaceSecondary};
    border: 1px solid ${theme.newColors.borderSecondaryBorderSecondary};
    box-shadow: ${theme.shadows['xl']};

    & > button {
      height: ${theme.spacing[20]};
    }

    & .title-icon {
      display: flex;
      color: ${theme.newColors.textPrimaryTextPrimary};
      font-weight: 500;
      font-size: ${theme.typography.fontSize['14'].size};
      font-family: ${theme.typography.fontFamily.inter};
    }

    & .texts {
      margin-left: ${theme.spacing['08']};
    }
  `}
`;

export const Title = styled(Toast.Title)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing['08']};
    color: ${theme.newColors.textSecondaryTextSecondary};
    font-family: ${theme.typography.fontFamily.inter};
  `}
`;

export const ToastViewport = styled(Toast.Viewport)`
  ${({ theme }) => css`
    --viewport-padding: ${theme.spacing[24]};
    position: fixed;
    z-index: 9;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing[24]};

    &.position-top-left {
      top: 0;
      left: 0;
    }

    &.position-top-right {
      top: 0;
      right: 0;
    }

    &.position-bottom-left {
      bottom: 0;
      left: 0;
    }

    &.position-bottom-right {
      bottom: 0;
      right: 0;
    }
  `}
`;

export const SvgIconBadgeInfo = styled(Info)`
  ${({ theme }) => css`
    color: ${theme.newColors.iconTertiaryIconTertiary};
    width: 16px;
    height: 16px;
  `}
`;

export const SvgIconBadgeSuccess = styled(CheckCircle)`
  ${({ theme }) => css`
    color: ${theme.newColors.iconSuccessIconSuccess};
    width: 16px;
    height: 16px;
  `}
`;

export const SvgIconBadgeError = styled(Cancel)`
  ${({ theme }) => css`
    color: ${theme.newColors.iconDangerIconDanger};
    width: 16px;
    height: 16px;
  `}
`;

export const SvgIconBadgeWarning = styled(Error)`
  ${({ theme }) => css`
    color: ${theme.newColors.iconWarningIconWarning};
    width: 16px;
    height: 16px;
  `}
`;
