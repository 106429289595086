import { ListProjectsByUserProps } from '@/services/project/get-list-projects-by-user';

export const mapperListProjectsByUser = (data: ListProjectsByUserProps[]) => {
  try {
    if (!Array.isArray(data)) {
      return [];
    }

    const projects = data.filter((item) => {
      return item.projectId && item.projectName;
    });

    projects.sort((a, b) => a.projectName.localeCompare(b.projectName));
    return projects;
  } catch (error) {
    return [];
  }
};
