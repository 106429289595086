'use cleint';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as S from './styles';

type RangeProps = {
  value: number;
};

export function Range({ value }: RangeProps) {
  const isPositiveValue = value > 0;

  return (
    <S.PercentageChangeContainer $isPositive={isPositiveValue}>
      {isPositiveValue ? (
        <S.WrapperIcon>
          <S.PositiveIcon>
            <ExpandLessIcon className="positiveIcon" />
            <S.Text>{value}</S.Text>
          </S.PositiveIcon>
        </S.WrapperIcon>
      ) : (
        <S.WrapperIcon>
          <S.NegativeIcon>
            <ExpandMoreIcon className="negativeIcon" />
            <S.Text>{value}</S.Text>
          </S.NegativeIcon>
        </S.WrapperIcon>
      )}
    </S.PercentageChangeContainer>
  );
}
