'use client';
import CopyrightIcon from '@mui/icons-material/Copyright';

import { SupportButton } from '../support-button';
import * as S from './styles';

const Footer = ({ isManager = false }: { isManager?: boolean }) => {
  return (
    <S.WrapperFooter>
      <S.FooterContainer data-testid="footer">
        <CopyrightIcon data-testid="copyright-icon" />
        2024 Assiny. Todos os direitos reservados.
        {isManager && <SupportButton />}
      </S.FooterContainer>
    </S.WrapperFooter>
  );
};

export { Footer };
