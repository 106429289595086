import {
  AnalyticsOutlined,
  Description,
  InsertDriveFileOutlined,
  Inventory2,
  ShowChartOutlined,
} from '@mui/icons-material';
import { MoneyOffCsred } from '@mui/icons-material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
type GetMenuOptionsProps = {
  organizationId?: string;
  projectId?: string;
  isProjectOwner?: boolean;
};

type OptionsMenuProps = {
  href: string;
  label: string;
  description: string;
  icon: JSX.Element;
  classNameLi?: string;
  handleClick?: () => void;
};

const sequenceOptionsUserByLabel = [
  'Dashboard',
  'Vendas',
  'Saque',
  'Downloads',
  'Assinaturas',
  'Pedidos de reembolso',
];

const sequenceOptionsProjectByLabel = [
  'Configurações financeiras',
  'Produtos',
  'Funil de vendas',
  'Alunos',
];

const sortByOptions = (
  optionsToSort: OptionsMenuProps[],
  sequenceOptions: string[]
) => {
  const sortedOptions = [] as OptionsMenuProps[];

  sequenceOptions.forEach((optionLabel) => {
    const option = optionsToSort.find(
      (optionToSort) => optionToSort.label === optionLabel
    );

    if (option) {
      sortedOptions.push(option);
    }
  });

  return sortedOptions;
};

const getFixedOptionsProject = (organizationId: string, projectId: string) => [
  {
    href: `/organizations/${organizationId}/projects/${projectId}/products`,
    label: 'Produtos',
    description: 'Gerencie todos os seus produtos',
    icon: <Inventory2 />,
  },
  {
    href: `/organizations/${organizationId}/projects/${projectId}/funnel`,
    label: 'Funil de vendas',
    description: 'Gerencie seus funis de vendas',
    icon: <FilterAltOutlinedIcon />,
  },
  {
    href: `/organizations/${organizationId}/projects/${projectId}/students`,
    label: 'Alunos',
    description: 'Gerencie os alunos e suas compras',
    icon: <GroupsOutlinedIcon />,
  },

  // {
  //   href: `/organizations/${organizationId}/projects/${projectId}/financial/transactions/reports`,
  //   label: 'Relatórios',
  //   description: 'Central de Relatórios',
  //   icon: <InsertDriveFileOutlined />,
  // },
];

const getMenuOptions = ({
  organizationId,
  projectId,
  isProjectOwner,
}: GetMenuOptionsProps) => {
  const FIXED_OPTIONS_USER = [
    {
      href: `/organizations/${organizationId}/projects/${projectId}`,
      label: 'Dashboard',
      description: 'Visualize as métricas do projeto',
      icon: <AnalyticsOutlined />,
    },
    {
      href: `/organizations/${organizationId}/projects/${projectId}/financial/transactions`,
      label: 'Vendas',
      description: 'Gerencie suas vendas e transações',
      icon: <ShowChartOutlined />,
    },
    {
      href: `/organizations/${organizationId}/projects/${projectId}/financial`,
      label: 'Saque',
      description: 'Consulte e efetue saques no projeto',
      icon: <AccountBalanceWalletOutlinedIcon />,
    },
    {
      href: `/organizations/${organizationId}/projects/${projectId}/financial/reports`,
      label: 'Downloads',
      description: 'Central de Downloads',
      icon: <FileDownloadRoundedIcon />,
    },
    {
      href: `/organizations/${organizationId}/projects/${projectId}/subscriptions`,
      label: 'Assinaturas',
      description: 'Gerencie suas assinaturas',
      icon: <CurrencyExchangeIcon />,
    },
    {
      href: `/organizations/${organizationId}/projects/${projectId}/refunds`,
      label: 'Pedidos de reembolso',
      description: 'Gerencie os pedidos de reembolso',
      icon: <MoneyOffCsred />,
    },
  ];

  if (!organizationId) {
    return {
      optionsProfile: FIXED_OPTIONS_USER,
    };
  }

  const itemsProfile = [
    {
      href: `/organizations/${organizationId}/projects`,
      label: 'Projetos',
      description: 'Veja quais projetos você faz parte',
      icon: <AppsIcon />,
    },
  ];

  // if (!projectId) {
  //   itemsProfile.push({
  //     href: `/organizations/${organizationId}/integrations`,
  //     label: 'Integrações',
  //     description: 'Gerencie suas integrações',
  //     icon: <LayersOutlinedIcon />,
  //   });

  //   return {
  //     optionsProfile: sortByOptions(
  //       [...FIXED_OPTIONS_USER, ...itemsProfile],
  //       sequenceOptionsUserByLabel
  //     ),
  //   };
  // }

  const itemsProject =
    organizationId && projectId
      ? getFixedOptionsProject(organizationId, projectId)
      : [];

  return {
    optionsProfile: sortByOptions(
      [...FIXED_OPTIONS_USER, ...itemsProfile],
      sequenceOptionsUserByLabel
    ),
    optionsProject: sortByOptions(itemsProject, sequenceOptionsProjectByLabel),
  };
};

export { getMenuOptions };
