'use client';

import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

import { Tooltip } from '@/components/tooltip';

import * as S from './styles';

export const SupportButton = () => {
  return (
    <S.Wrapper>
      <Tooltip
        label={
          <S.LinkButton
            href={
              'https://forms.clickup.com/31032041/f/xk0q9-64653/AJVLP7U9AJ189EKYVB'
            }
            target="_blank">
            <BugReportOutlinedIcon className="image" />
          </S.LinkButton>
        }
        contrast="high"
        description="Reportar problema"
      />
    </S.Wrapper>
  );
};
