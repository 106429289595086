'use client';

import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  ReactNode,
} from 'react';

import clsx from 'clsx';

import * as S from './styles';

type ButtonCustomProps = {
  icon?: ReactNode;
  isLoading?: boolean;
  element?: React.ElementType | string;
  theme?: 'light' | 'dark';
  width?: 'stretch' | 'full';
} & S.ButtonStyleProps;

type ButtonProps = (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
) &
  ButtonCustomProps;

const Button: FC<ButtonProps> = ({
  children,
  size = 'sm',
  color = 'primary',
  radius = 'rounded',
  icon,
  iconPosition = 'left',
  disable = false,
  className,
  isLoading,
  width = 'stretch',
  element = 'button',
  theme = 'light',
  ...props
}) => {
  return (
    <S.StyledButton
      disabled={disable}
      className={clsx(
        `size-${size}`,
        `radius-${radius}`,
        `type-${color}`,
        `width-${width}`,
        disable && 'disable',
        iconPosition && `iconPosition-${iconPosition}`,
        !children && 'iconOnly',
        className
      )}
      $theme={theme}
      as={element}
      {...props}>
      {icon && icon}
      {children && children}
      {isLoading && <S.LoadingIcon />}
    </S.StyledButton>
  );
};

export { Button };
