'use client';

import { Size } from '@/utils/enums';
import { DialogContentProps } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import * as S from './styles';

export type ModalContentProps = {
  contentPosition?: 'center' | 'left';
  children: React.ReactNode;
  size?: Size;
  zIndex?: number;
  openPosition?: S.Position;
} & DialogContentProps;

export const ModalContent = ({
  contentPosition = 'left',
  children,
  size,
  openPosition,
  zIndex,
  ...props
}: ModalContentProps) => {
  const className = clsx(`position-${contentPosition}`, size, props.className);
  return (
    <>
      <S.TitleComponent className="DialogTitle"></S.TitleComponent>
      <S.ContentComponent
        {...props}
        className={className}
        $position={openPosition}
        style={{ zIndex }}>
        {children}
      </S.ContentComponent>
    </>
  );
};
