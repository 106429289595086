'use client';
import { Size } from '@/utils/enums';
import { TabsProps as TTabsProps } from '@radix-ui/react-tabs';
import clsx from 'clsx';

import * as S from './styles';

type switchSize = Exclude<Size, 'lg'>;

type TabsProps = {
  orientation?: 'horizontal' | 'vertical';
  size?: switchSize;
  items: Content[];
  value?: string;
  theme?: 'dark' | 'light';
  onValueChange?: (value: string) => void;
} & TTabsProps;

type Content = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  label: string;
  value: string;
};

const Tab = ({
  orientation = 'horizontal',
  size = 'md',
  items,
  value,
  theme = 'light',
  onValueChange,
}: TabsProps) => {
  const containerClassName = clsx(`size-${size}`, `style-${theme}`);

  return (
    <S.TabsRootStyled
      className="TabsRoot"
      defaultValue={items[0].value}
      orientation={orientation}
      value={value}
      onValueChange={onValueChange}>
      <S.TabsListStyled className="TabsList">
        {items.map(({ icon, value, label }) => (
          <S.TabsTriggerStyled
            key={value}
            className={containerClassName}
            value={value}>
            {icon && icon} {label}
          </S.TabsTriggerStyled>
        ))}
      </S.TabsListStyled>

      {items.map(({ children, value }) => (
        <S.TabsContentStyled key={value} className="TabsContent" value={value}>
          {children}
        </S.TabsContentStyled>
      ))}
    </S.TabsRootStyled>
  );
};

export { Tab };
