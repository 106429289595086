/* eslint-disable @typescript-eslint/ban-types */
'use client';

import React, { InputHTMLAttributes, useState } from 'react';
import {
  Controller,
  Control,
  UseFormRegister,
  FieldValues,
  FieldValue,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { TagsInput } from 'react-tag-input-component';

import { Size } from '@/utils/enums';
import { InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import clsx from 'clsx';

import { Tooltip } from '@/components/tooltip';

import * as S from './styles';

interface InputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'size' | 'prefix' | 'icon' | 'maxlenght'
  > {
  icon?: React.ReactNode;
  counterValue?: string;
  label?: string;
  maxLenght?: number;
  hasCounter?: boolean;
  labelSuffix?: string;
  placeholder?: string;
  size?: Size | string;
  error?: boolean;
  errorMessage?: string;
  prefix?: boolean;
  prefixText?: string;
  noBackground?: boolean;
  focus?: boolean;
  suffix?: boolean;
  suffixText?: string;
  defaultValue?: string;
  disabled?: boolean;
  password?: boolean;
  classNameWrapper?: string;
  name?: string;
  mask?: string;
  flatSide?: 'left' | 'right' | 'none';
  control?: Control<FieldValue<FieldValues>>;
  register?: UseFormRegister<FieldValue<FieldValues>>;
  fixedText?: string;
  description?: string;
  isMult?: boolean;
  hasLP?: boolean;
  has1P?: boolean;
  tooltip?: string;
}

const Input = ({
  label,
  labelSuffix,
  placeholder,
  error,
  errorMessage,
  size = 'sm',
  disabled = false,
  prefix,
  prefixText = '',
  noBackground,
  focus = true,
  suffix,
  suffixText = '',
  defaultValue,
  icon,
  password = false,
  classNameWrapper,
  name = 'input',
  mask,
  flatSide = 'none',
  control,
  register,
  fixedText,
  description,
  isMult = false,
  has1P = false,
  hasLP = false,
  tooltip = '',
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const isReactHookForm = !!register && !!name;
  const registerInfo = isReactHookForm && register(name);

  const commonClassName = clsx(`size-${size}`, `border-${flatSide}`, {
    error: error,
    disabled: disabled,
    noBackground: noBackground,
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const renderIcon = () =>
    icon && <span style={{ display: 'flex' }}>{icon}</span>;

  const renderInput = () => {
    if (mask) {
      return (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <InputMask
                mask={mask}
                disabled={disabled}
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e);
                }}>
                <S.Input
                  role="textbox"
                  placeholder={placeholder}
                  className={commonClassName}
                  type={'text'}
                  data-lpignore={!hasLP}
                  data-1p-ignore={!has1P}
                  autoComplete="off"
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedData = e.clipboardData
                      .getData('Text')
                      .replace(/\D/g, '');
                    field.onChange(pastedData);
                  }}
                />
              </InputMask>
            );
          }}
        />
      );
    }

    if (isMult) {
      return (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            const handleOnChange = (newTags: string[]) => {
              field.onChange(newTags);
            };

            const handleOnKeyUp = (
              e: React.KeyboardEvent<HTMLInputElement>
            ) => {
              const inputValue = e.currentTarget.value;

              if (inputValue.endsWith(', ')) {
                const newTag = inputValue.slice(0, -2).trim();
                if (newTag) {
                  const updatedTags = [...(field.value || []), newTag];
                  field.onChange(updatedTags);
                  e.currentTarget.value = '';
                }
              }
            };

            return (
              <S.MultInput>
                <TagsInput
                  value={field.value || []}
                  onChange={handleOnChange}
                  placeHolder={placeholder}
                  onKeyUp={handleOnKeyUp}
                  separators={[]}
                />
              </S.MultInput>
            );
          }}
        />
      );
    }

    return (
      <S.Input
        role="textbox"
        aria-label={name}
        defaultValue={defaultValue}
        disabled={disabled}
        className={commonClassName}
        type={password ? (showPassword ? 'text' : 'password') : 'text'}
        data-lpignore="true"
        data-1p-ignore="true"
        autoComplete="off"
        placeholder={placeholder}
        {...registerInfo}
        {...rest}
        onChange={(e) => {
          if (registerInfo && registerInfo.onChange) {
            registerInfo.onChange(e);
          }
        }}
      />
    );
  };

  return (
    <div className={classNameWrapper}>
      {label && (
        <S.Label htmlFor={name}>
          <span>
            {label}
            {!!tooltip.length && (
              <Tooltip
                label={<InfoOutlined />}
                modal="true"
                contrast="low"
                description={tooltip}
              />
            )}
          </span>
          {labelSuffix ? (
            <S.OptionalLabel>{labelSuffix}</S.OptionalLabel>
          ) : null}
        </S.Label>
      )}
      <S.InputContainer hasFocus={focus} className={commonClassName}>
        {fixedText && (
          <S.FixedText className={commonClassName}>{fixedText}</S.FixedText>
        )}
        {prefix && (
          <S.PrefixLabel $isImage={!!icon} className={commonClassName}>
            {renderIcon()}
            {prefixText}
          </S.PrefixLabel>
        )}

        {renderInput()}

        {password && (
          <S.ButtonPassword
            type="button"
            data-testid="button-password"
            onClick={handleTogglePasswordVisibility}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </S.ButtonPassword>
        )}
        {suffix && (
          <S.SuffixLabel className={commonClassName}>
            {suffixText}
            {renderIcon()}
          </S.SuffixLabel>
        )}
      </S.InputContainer>

      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      {!error && description && (
        <S.DescriptionMessage>{description}</S.DescriptionMessage>
      )}
    </div>
  );
};

export { Input };
