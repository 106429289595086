'use client';

import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/toastStyles.css';
export default function ToastContainerWrapper() {
  const contextClass: { [key: string]: string } = {
    success: 'toast success',
    error: 'toast error',
    info: 'toast info',
    warning: 'toast warning',
    default: 'toast default',
    dark: 'toast dark',
  };

  return (
    <ToastContainer
      position="bottom-right"
      theme="colored"
      hideProgressBar
      toastClassName={({ type }: TypeOptions | any) =>
        contextClass[type || 'default']
      }
    />
  );
}
