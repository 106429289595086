import styled, { css } from 'styled-components';

export const WrapperFooter = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[50]};
    border-top: 1px solid ${theme.colors.gray[200]};
  `}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1024px;
    margin: 0 auto;
    gap: ${theme.spacing['04']};
    color: ${theme.colors.gray[400]};
    padding: ${theme.spacing[16]};
    font-size: ${theme.typography.fontSize['12'].size};
    position: relative;

    svg {
      width: 12px;
      height: 12px;
    }

    ${theme.breakpoints.laptop} {
      justify-content: start;
    }
  `}
`;
