import { DragEvent, useEffect, useState } from 'react';
import { Control, Controller, FieldValue, FieldValues } from 'react-hook-form';

import { toBase64 } from '@/utils/image';
import { isValidUrl } from '@/utils/string';
import { ImageOutlined as ImageIcon } from '@mui/icons-material';

import * as S from './styles';

type ImageInputProps = {
  label?: string;
  name?: string;
  control?: Control<FieldValue<FieldValues>>;
  error?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  resetPreview?: boolean;
  isMultiple?: boolean;
};

export const ImageInput = ({
  label = '',
  control,
  name = 'image',
  error,
  errorMessage,
  defaultValue,
  resetPreview = false,
  isMultiple = false,
}: ImageInputProps) => {
  const [preview, setPreview] = useState<File | File[] | string | undefined>(
    defaultValue || ''
  );

  const handleImage = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    const loadedImage: File[] = Array.from(files);
    setPreview(loadedImage);
  };

  const [windowWidth, setWindowWidth] = useState(0);

  const handleDragEnter = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    setPreview(file);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (resetPreview) {
      setPreview('');
    }
  }, [resetPreview]);

  return (
    <S.Container>
      <span>{label}</span>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field } }) => {
          if (isMultiple) {
            setPreview(!!value?.length && value);
          } else {
            setPreview(value);
          }

          return (
            <S.ImageCanvas
              htmlFor={name}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={(event) => {
                handleDrop(event);

                const loadedImage = event.dataTransfer.files[0];

                onChange(loadedImage ? loadedImage : '');
              }}>
              <input
                {...field}
                type="file"
                onChange={async (event) => {
                  handleImage(event.nativeEvent);
                  if (!isMultiple) {
                    if (event.target.files) {
                      onChange(event.target.files[0]);
                    }
                  } else {
                    const files = event.target.files
                      ? await Promise.all(
                          Array.from(event.target.files).map(
                            async (item, index) => {
                              return {
                                order: index,
                                imageSource: await toBase64(item as never),
                              };
                            }
                          )
                        )
                      : [];
                    onChange(files);
                  }
                }}
                name={name}
                id={name}
                multiple={isMultiple}
                accept="image/*"
                style={{ display: 'none' }}
              />
              {!preview ? (
                <S.NoImageText>
                  <ImageIcon className="icon" />
                  {windowWidth < 720 ? (
                    <span className="instruction">
                      <strong>Selecione do seu celular</strong>
                    </span>
                  ) : (
                    <span className="instruction">
                      <strong>Selecione do computador</strong> ou arraste para
                      cá
                    </span>
                  )}
                  <span className="supported-images">
                    PNG, JPG, JPEG até 10MB
                  </span>
                </S.NoImageText>
              ) : (
                <>
                  {Array.isArray(preview) ? (
                    preview.map((item: any, index) => {
                      return (
                        <S.ImagePreview
                          key={index}
                          src={
                            isValidUrl(item.imageSource)
                              ? (item.imageSource as never)
                              : URL.createObjectURL(item as File)
                          }
                          alt="pré visualização da imagem enviada"
                          $isMultiple={isMultiple}
                        />
                      );
                    })
                  ) : (
                    <S.ImagePreview
                      src={
                        isValidUrl(preview)
                          ? (preview as never)
                          : URL.createObjectURL(preview as File)
                      }
                      alt="pré visualização da imagem enviada"
                      $isMultiple={isMultiple}
                    />
                  )}
                </>
              )}
            </S.ImageCanvas>
          );
        }}
      />
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Container>
  );
};
