import { UserCredential } from 'firebase/auth';

export async function handleLogout() {
  return fetch('/api/signOut', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function handleLogin(userCred: UserCredential) {
  return fetch('/api/login', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await userCred.user.getIdToken()}`,
      RefreshToken: userCred.user.refreshToken,
    },
  });
}
