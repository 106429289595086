import * as Select from '@radix-ui/react-select';
import styled, { css } from 'styled-components';

interface BoxesTriggerProps {
  $isChecked: boolean;
}

export const BoxesTrigger = styled(Select.Trigger)<BoxesTriggerProps>`
  ${({ theme, $isChecked }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: ${theme.radius.xs};
    border: 1px solid ${theme.colors.gray['300']};
    gap: ${theme.spacing['08']};
    padding: ${theme.spacing[24]};
    font-weight: ${theme.typography.fontWeight.semiBold};
    width: fit-content;

    &:hover {
      border-color: ${theme.colors.accent['500']};
    }

    &[disabled] {
      color: ${theme.colors.gray['400']};
      background-color: ${theme.colors.gray['200']};
      border: none;
      cursor: not-allowed;
    }

    & > .checkbox {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        width: ${theme.spacing[24]};
        height: ${theme.spacing[24]};
      }
    }

    &.variations-checkIcon {
      padding: ${theme.spacing['48']} ${theme.spacing['24']};
    }

    ${$isChecked &&
    css`
      color: ${theme.colors.accent['500']};
      background-color: ${theme.colors.accent['50']};
      border-color: ${theme.colors.accent['500']};
    `}
  `}
`;
