'use client';

import {
  forwardRef,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from 'react';

import * as S from './styles';

const Table = forwardRef<
  HTMLTableElement,
  HTMLAttributes<HTMLTableElement> & { isRounded?: boolean }
>(({ className, isRounded, ...props }, ref) => (
  <S.WrapperTable className={`wrapperTable ${isRounded ? 'rounded' : ''}`}>
    <S.Table cellSpacing={0} ref={ref} className={className} {...props} />
  </S.WrapperTable>
));

Table.displayName = 'Table';

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <S.TableHeader ref={ref} className={className} {...props} />
));

TableHeader.displayName = 'TableHeader';

const TableBody = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <S.TableBody ref={ref} className={className} {...props} />
));

TableBody.displayName = 'TableBody';

const TableFooter = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <S.TableFooter ref={ref} className={className} {...props} />
));

TableFooter.displayName = 'TableFooter';

const TableRow = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement> & { isHidden?: boolean }
>(({ className, isHidden, ...props }, ref) => (
  <S.TableRow ref={ref} className={className} {...props} />
));

TableRow.displayName = 'TableRow';

const TableHeaderCell = forwardRef<
  HTMLTableCellElement,
  ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <S.TableHeaderCell ref={ref} className={className} {...props} />
));
TableHeaderCell.displayName = 'TableHeaderCell';

const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <S.TableCell ref={ref} className={className} {...props} />
));
TableCell.displayName = 'TableCell';

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHeaderCell,
  TableRow,
  TableCell,
};
