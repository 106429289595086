'use client';

import React from 'react';

import { queryClient } from '@/config/query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function ReactQueryContext({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </div>
    </QueryClientProvider>
  );
}

export default ReactQueryContext;
