import { Root, Item, Indicator } from '@radix-ui/react-radio-group';
import styled, { css } from 'styled-components';

export const RadioGroupRoot = styled(Root)`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing[12]};

    &[data-orientation='vertical'] {
      flex-direction: column;
    }
  `}
`;

export const RadioGroupItem = styled(Item)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: ${theme.spacing[16]};
    height: ${theme.spacing[16]};
    border-radius: 100%;
    border: 1px solid ${theme.colors.gray[300]};
    flex-shrink: 0;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 4px ${theme.colors.gray[100]};
      border-color: ${theme.colors.accent[500]};
      outline: none;
    }

    &:hover {
      background-color: ${theme.colors.gray[100]};
    }
  `}
`;

export const RadioGroupIndicator = styled(Indicator)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: ${theme.spacing['06']};
      height: ${theme.spacing['06']};
      border-radius: ${theme.radius['circle']};
      box-shadow: 0 0 0 0.3em ${theme.colors.accent[500]};
    }
  `}
`;

type LabeProps = {
  $bold: boolean;
};

export const Label = styled.label<LabeProps>`
  ${({ theme, $bold }) => css`
    display: flex;
    flex-direction: column;
    cursor: pointer;

    span:first-child {
      color: ${theme.colors.gray[600]};
      font-size: ${theme.typography.fontSize[12].size};
      line-height: ${theme.typography.fontSize[12].lineHeight};

      display: flex;
      align-items: flex-start;
      gap: 6px;

      position: relative;

      & > div > svg {
        color: ${theme.newColors.textSecondaryTextSecondary};
        width: 1rem;
        height 1rem;
        position: absolute;
        top: -4px;
      }

      & > .withImage {
        font-weight: ${theme.typography.fontWeight.bold};
        display: inherit;
        align-items: center;
        gap: inherit;
        color: ${theme.newColors.textPrimaryTextPrimary};
        font-size: inherit;
        line-height: inherit;
      }

      ${
        $bold &&
        css`
          color: ${theme.colors.gray[900]};
          font-weight: ${theme.typography.fontWeight.bold};
        `
      }
    }
  `}
`;

type RadioGroupItemContainerProps = {
  $inverse: boolean;
};

export const RadioGroupItemContainer = styled.div<RadioGroupItemContainerProps>`
  ${({ theme, $inverse }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['08']};

    ${$inverse &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
    `}
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray[600]};
    font-size: ${theme.typography.fontSize[12].size};
    line-height: ${theme.typography.fontSize[12].lineHeight};
  `}
`;
