'use client';

import { ReactNode } from 'react';

import { Portal, PortalProps } from '@radix-ui/react-select';

type ModalPortalProps = {
  children: ReactNode;
} & PortalProps;

export const ModalPortal = ({ children, ...props }: ModalPortalProps) => {
  return <Portal {...props}>{children}</Portal>;
};
