'use client';

import styled, { css } from 'styled-components';

type SkeletonProps = {
  width?: string;
  height?: string;
};

export const Skeleton = styled.div<SkeletonProps>`
  ${({ width = '100%', height = '24px' }) => css`
    width: ${width};
    height: ${height};
    border-radius: 60px;
    background: linear-gradient(
      270deg,
      rgba(219, 219, 219, 0.05) 0%,
      #dbdbdb 50%
    );
  `}
`;
