'use client';

import { DialogOverlayProps } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import * as S from './styles';

type BackgroundProps = 'blur' | 'unset';

type ModalOverlayProps = {
  background?: BackgroundProps;
  zIndex?: number;
} & DialogOverlayProps;

export const ModalOverlay = ({
  background = 'blur',
  zIndex,
  ...props
}: ModalOverlayProps) => {
  const className = clsx(`background-${background}`, props.className);

  return (
    <S.OverlayComponent
      className={className}
      style={{ zIndex }}
      {...props}
      data-testid="overlay"
    />
  );
};
