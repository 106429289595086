'use client';

import { ReactNode } from 'react';

import { DialogTriggerProps, Trigger } from '@radix-ui/react-dialog';

type ModalTriggerProps = {
  children: ReactNode;
} & DialogTriggerProps;

export const ModalTrigger = ({
  children,
  asChild,
  ...props
}: ModalTriggerProps) => {
  return (
    <Trigger {...props} asChild={asChild}>
      {children}
    </Trigger>
  );
};
