import { boolean, object, string } from 'yup';

export const schemaLogin = object({
  email: string()
    .email('Email inválido')
    .required('Email é obrigatório')
    .trim(),
  password: string()
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .required('Senha é obrigatório')
    .trim(),
  rememberMe: boolean().nullable(),
});

export type SchemaLoginProps = ReturnType<typeof schemaLogin.validateSync>;
