import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';

interface GlobalContextData {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  children: ReactNode;
}

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData);

export function GlobalProvider({ children }: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const values = { open, setOpen };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
}

export function useGlobal() {
  const context = useContext(GlobalContext);

  return context;
}
