import styled, { css } from 'styled-components';

export const Main = styled.main`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing['64']};
    max-width: 1024px;
    margin: 0 auto;
    padding: ${theme.spacing['24']} ${theme.spacing['16']}
      ${theme.spacing['40']} ${theme.spacing['16']};

    ${theme.breakpoints.tablet720} {
      align-items: center;
      height: 100vh;
      padding: ${theme.spacing['24']} ${theme.spacing['48']}
        ${theme.spacing['40']} ${theme.spacing['48']};
    }

    ${theme.breakpoints.laptop} {
      padding: ${theme.spacing['40']};
    }

    & > .sectionForm {
      width: 100%;

      ${theme.breakpoints.laptop} {
        flex: 1;
      }

      & > {
        &.wrapperNotAccount {
          display: flex;
          gap: ${theme.spacing['04']};
          margin-top: ${theme.spacing['24']};
          justify-content: center;
        }

        &.buttonGoogle {
          justify-content: center;
          margin-bottom: ${theme.spacing['24']};
          width: 100%;
        }

        &.buttonOutlock {
          justify-content: center;
          margin-bottom: ${theme.spacing['24']};
          width: 100%;
        }

        &.divider {
          margin-bottom: ${theme.spacing['24']};
        }
      }
    }

    & > .sectionImage {
      display: flex;
      flex: 1;
      height: 597px;
      position: relative;
    }
  `}
`;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing['16']};

    & > .wrapperForgotPassword {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &.buttonLogin {
      margin-bottom: ${theme.spacing['24']};
      text-align: center;
      width: 100%;
    }
  `}
`;

export const WrapperImage = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing['32']};
    width: 100%;

    ${theme.breakpoints.laptop} {
      justify-content: left;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.gray['900']};
    font-size: ${theme.typography.fontSize['30'].size};
    font-weight: ${theme.typography.fontWeight.semiBold};
    line-height: ${theme.typography.fontSize['30'].lineHeight};
    margin-bottom: ${theme.spacing['08']};
    text-align: center;

    ${theme.breakpoints.laptop} {
      text-align: left;
    }
  `}
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.gray['600']};
    font-size: ${theme.typography.fontSize['16'].size};
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: ${theme.typography.fontSize['16'].lineHeight};
    margin-bottom: ${theme.spacing['32']};
    text-align: center;

    ${theme.breakpoints.laptop} {
      text-align: left;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray['600']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: 600;
    line-height: ${theme.typography.fontSize['14'].lineHeight};
  `}
`;
