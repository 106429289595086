import styled, { css } from 'styled-components';

interface TipsProps {
  type?: 'info' | 'warning' | 'error' | 'success' | 'tip' | 'error-warning';
}

export const Tips = styled.div<TipsProps>`
  ${({ theme, type }) => {
    let backgroundColor = '#f4f3ff';
    switch (type) {
      case 'info':
      case 'tip':
        backgroundColor = '#6938EF';
        break;
      case 'warning':
        backgroundColor = '#EA580C';
        break;
      case 'error':
      case 'error-warning':
        backgroundColor = '#D92D20';
        break;
      default:
        backgroundColor = '#059669';
        break;
    }
    return css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 12px;
      border-radius: 6px;
      border: solid 1px ${backgroundColor}47;
      background-color: ${backgroundColor}12;
      > span {
        width: 4px;
        min-width: 4px;
        align-self: stretch;
        flex-grow: 0;
        border-radius: 6px;
        background-color: ${backgroundColor};
      }
      > label {
        font-family: ${theme.typography.fontFamily.inter};
        font-size: 14px;
        font-weight: ${theme.typography.fontWeight.medium};
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #3f3f46;
        > span {
          font-weight: ${theme.typography.fontWeight.semiBold};
        }
      }
    `;
  }}
`;
