export const isValidUrl = (urlString: string | File | File[]) => {
  try {
    if (typeof urlString === 'string') {
      return Boolean(new URL(urlString));
    }
  } catch (e) {
    return false;
  }
};

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
