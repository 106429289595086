import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as Accordion from '@radix-ui/react-accordion';
import styled, { keyframes, css } from 'styled-components';

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

export const AccordionItem = styled(Accordion.Item)`
  overflow: hidden;
  width: 400px;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  ${({ theme }) => css`
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${theme.radius.xs};
    color: ${theme.colors.gray[900]};
    font-size: ${theme.typography.fontSize['16'].size};
    font-weight: ${theme.typography.fontWeight.medium};

    // &[data-disabled='true'] {
    //   pointer-events: none;
    // }

    &.size-sm {
      padding: ${theme.spacing['04']} ${theme.spacing[16]};
    }
    &.size-md {
      padding: ${theme.spacing['08']} ${theme.spacing[16]};
    }
    &.size-lg {
      padding: ${theme.spacing[12]} ${theme.spacing[16]};
    }

    &.contrast-low {
      background-color: ${theme.colors.white};
      border-top: 1px solid ${theme.colors.gray[100]};

      &:hover {
        background-color: ${theme.colors.gray[100]};
      }
      &:focus {
        border: 5px solid ${theme.colors.gray[100]};
      }
    }
    &.contrast-high {
      background-color: ${theme.colors.gray[200]};

      &:hover {
        background-color: ${theme.colors.gray[300]};
      }
      &:focus {
        border: 5px solid ${theme.colors.gray[200]};
      }
    }
    cursor: pointer;
  `}
`;

export const AccordionContent = styled(Accordion.Content)`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.typography.fontSize['16'].size};
    line-height: ${theme.typography.fontSize['16'].lineHeight};
    padding: ${theme.spacing['08']} ${theme.spacing[16]} ${theme.spacing[16]};
    color: ${theme.colors.gray[900]};

    &[data-state='open'] {
      animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    &[data-state='closed'] {
      animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[hidden] {
      display: none;
    }
  `};
`;

export const AccordionHeader = styled(Accordion.Header)`
  display: flex;
`;

export const SvgArrow = styled(KeyboardArrowDownIcon)`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    color: ${theme.colors.gray[900]};
    top: 1px;
    transition: transform 250ms ease;

    ${AccordionTrigger}[data-state='open'] & {
      transform: rotate(-180deg);
    }
  `}
`;
