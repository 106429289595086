import styled, { css } from 'styled-components';

export const AvatarPlusStyled = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.radius.circle};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${theme.spacing['08']};
    user-select: none;
    &:hover {
      background: ${theme.colors.gray[50]};
    }
    &:active {
      outline: 4.5px solid ${theme.colors.gray[100]};
    }
    &:focus {
      outline: 4.5px solid ${theme.colors.gray[100]};
    }

    &.state-default .inner-border {
      border: 1px dashed ${theme.colors.gray[300]};
      border-radius: ${theme.radius.circle};
    }
    &.state-disable {
      border: 1px dashed ${theme.colors.gray[100]};
      background-color: #f9fafb;
      cursor: not-allowed;
      border-radius: ${theme.radius.circle};
    }
    &.plus-icon {
      fill: ${theme.colors.gray[700]};
    }
    &.state-disable .plus-icon {
      border: 1px dashed ${theme.colors.gray[100]};
      background-color: ${theme.colors.gray[50]};
      fill: #eaecf0;
      cursor: not-allowed;
      border-radius: ${theme.radius.circle};
    }

    &.state-default {
      border: 1px dashed ${theme.colors.gray[300]};
    }
    &.size-sm {
      width: ${theme.spacing['24']};
      height: ${theme.spacing['24']};
    }
    &.size-sm .svg {
      width: ${theme.spacing['24']};
      height: ${theme.spacing['24']};
    }
    &.size-md {
      width: ${theme.spacing['32']};
      height: ${theme.spacing['32']};
    }
    &.size-md .svg {
      width: ${theme.spacing['32']};
      height: ${theme.spacing['32']};
    }
    &.size-lg {
      width: ${theme.spacing['40']};
      height: ${theme.spacing['40']};
    }
    &.size-lg .svg {
      width: ${theme.spacing['40']};
      height: ${theme.spacing['40']};
    }
    &.size-xl {
      width: ${theme.spacing['48']};
      height: ${theme.spacing['48']};
    }
    &.size-xl .svg {
      width: ${theme.spacing['48']};
      height: ${theme.spacing['48']};
    }
  `}
`;
export const AvatarExtraStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.gray[100]};
    border-radius: ${theme.radius.circle};
    color: ${theme.colors.gray[700]};
    font-family: ${theme.typography.fontFamily.inter};
    user-select: none;

    &.size-sm {
      width: ${theme.spacing['24']};
      height: ${theme.spacing['24']};
      font-size: 10.5px;
      font-weight: ${theme.typography.fontWeight.bold};
    }
    &.size-md {
      width: ${theme.spacing['32']};
      height: ${theme.spacing['32']};
      font-size: ${theme.typography.fontSize['14']};
      font-weight: ${theme.typography.fontWeight.bold};
    }
    &.size-lg {
      width: ${theme.spacing['40']};
      height: ${theme.spacing['40']};
      font-size: ${theme.typography.fontSize['16']};
      font-weight: ${theme.typography.fontWeight.bold};
    }
    &.size-xl {
      font-size: ${theme.typography.fontSize['18']};
      width: ${theme.spacing['48']};
      height: ${theme.spacing['48']};
      font-weight: ${theme.typography.fontWeight.bold};
    }
  `}
`;

export const AvatarGroupStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: auto;
    padding-left: ${theme.spacing['08']};

    .single-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${theme.radius.circle};
      border: 1.5px solid ${theme.colors.white};
      margin-left: -8px;
    }
  `}
`;
