'use client';

import { checkWhiteListEmail } from '@/services/register/checkWhitelist';
import { UserProps } from '@/utils/enums/user';
import { RequestParamsErrorResponseProps } from '@/utils/request';
import { useMutation } from '@tanstack/react-query';

export const useCheckEmailWhitelist = () => {
  return useMutation({
    mutationKey: ['check-email-whitelist'],
    mutationFn: async (email: string) => {
      const data = await checkWhiteListEmail(email);
      return data as unknown as boolean;
    },
    onError: (error: RequestParamsErrorResponseProps) => {
      return error;
    },
  });
};
