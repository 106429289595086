import styled, { css } from 'styled-components';

export const Label = styled.span`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing['08']};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${theme.colors.gray[900]};
  `}
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;

export const EditorContainer = styled.div`
  ${({ theme }) => css`
    .ql-toolbar {
      border-radius: 12px 12px 0 0;
      background: ${theme.colors.gray[100]};
    }

    .ql-container {
      min-height: 200px;
      border-radius: 0 0 12px 12px;
      font-family: ${theme.typography.fontFamily.inter};
    }

    .ql-blank {
      min-height: 200px;
    }
  `}
`;
