'use client';

import { ReactNode } from 'react';

import { DialogProps, Root } from '@radix-ui/react-dialog';

type ModalRootProps = {
  children: ReactNode;
} & DialogProps;

export const ModalRoot = ({
  children,
  defaultOpen,
  ...props
}: ModalRootProps) => {
  return (
    <Root {...props} defaultOpen={defaultOpen}>
      {children}
    </Root>
  );
};
