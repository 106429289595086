'use client';

import { Size } from '@/utils/enums';
import clsx from 'clsx';

import { AvatarImage } from '../avatar-image';
import * as S from './styles';

type UserArrayProps = {
  name?: string;
  image?: string;
  id: number;
}[];

type AvatarGroupProps = {
  size: Size | 'xl';
  users: UserArrayProps;
  plusSign: boolean;
  extraUser?: boolean;
  state?: 'default' | 'disable';
};

type PlusAvatarProps = {
  size?: Size | 'xl';
  state?: 'default' | 'disable';
};

export function AvatarGroup({
  size,
  users,
  plusSign,
  extraUser,
  state,
}: AvatarGroupProps) {
  const AddSVG = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={clsx('plus-icon', `size-${size}`, `state-${state}`)}
        viewBox="0 0 16 16">
        <path d="M12.375 8.625H8.625V12.375H7.375V8.625H3.625V7.375H7.375V3.625H8.625V7.375H12.375V8.625Z" />
      </svg>
    );
  };

  const PlusAvatar = ({ size, state = 'default' }: PlusAvatarProps) => {
    return (
      <S.AvatarPlusStyled
        className={clsx('plus-icon', `size-${size}`, `state-${state}`)}>
        <AddSVG />
      </S.AvatarPlusStyled>
    );
  };

  const extraUsersAvatar = (
    size: Size | 'xl',
    users: UserArrayProps,
    extraUsers: boolean
  ) => {
    if (extraUsers) {
      return (
        <S.AvatarExtraStyled className={clsx('extra-avatar', `size-${size}`)}>
          +{users.length - 5}
        </S.AvatarExtraStyled>
      );
    }
  };

  return (
    <S.AvatarGroupStyled data-testid="avatar-group">
      {users.slice(0, 5).map((user) => (
        <div key={user.id} className="single-avatar" style={{ zIndex: 1 }}>
          <AvatarImage
            size={size}
            className="avatar"
            name={user.name}
            image={user.image}
          />
        </div>
      ))}
      <div className="single-avatar" style={{ zIndex: 1 }}>
        {extraUser && extraUsersAvatar(size, users, extraUser)}
      </div>
      {plusSign && <PlusAvatar size={size} state={state} />}
    </S.AvatarGroupStyled>
  );
}
