'use client';
import { useEffect } from 'react';

import { useLottie } from 'lottie-react';

import assinyLoadingAnimation from '../../../public/assets/animations/assinyLoadingAnimation.json';
import * as S from './styles';

interface LoadingProps {
  height?: string;
}

export default function Loading({ height = '100vh' }: LoadingProps) {
  const options = {
    animationData: assinyLoadingAnimation,
    loop: true,
  };

  const { View, setSpeed } = useLottie(options);

  useEffect(() => {
    setSpeed(2.5);
  }, []);

  return (
    <S.LoadingContainer height={height}>
      {View && <div>{View}</div>}
    </S.LoadingContainer>
  );
}
