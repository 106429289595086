'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';

import * as S from './styles';

interface MenuItem {
  href?: string;
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  classNameLi?: string;
  handleClick?: () => void;
}

export interface NavDropdownProps {
  label?: string;
  items?: MenuItem[];
  type?: 'default' | 'projectList';
  viewPortPosition?: 'viewPortUnset';
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  label,
  items,
  type = 'default',
  viewPortPosition,
}) => {
  const triggerClasses = clsx(
    {
      projectList: type === 'projectList',
    },
    viewPortPosition
  );

  return (
    <S.NavigationMenuRoot data-testid="nav-dropdown">
      <S.NavigationMenuList>
        <NavigationMenu.Item>
          <S.NavigationMenuTrigger
            onClick={(event) => event.preventDefault()}
            className={triggerClasses}>
            {label}

            <S.SvgDown className={triggerClasses} />
          </S.NavigationMenuTrigger>

          <S.NavigationMenuContent>
            <S.ListUl>
              {items?.map((item, index) => (
                <S.ListLi key={index} className={item?.classNameLi}>
                  <S.ListLink
                    prefetch={false}
                    target={item?.href?.includes('https') ? '_blank' : '_self'}
                    href={item?.href || ''}
                    onClick={item?.handleClick}>
                    <S.LinkContainer>
                      {item.icon}
                      <S.ContentWrapper>
                        {item.label}
                        <S.ListDescription>
                          {item.description}
                        </S.ListDescription>
                      </S.ContentWrapper>
                    </S.LinkContainer>
                  </S.ListLink>
                </S.ListLi>
              ))}
            </S.ListUl>
          </S.NavigationMenuContent>
        </NavigationMenu.Item>
      </S.NavigationMenuList>

      <S.ViewportPosition className={triggerClasses}>
        <S.NavigationMenuViewport />
      </S.ViewportPosition>
    </S.NavigationMenuRoot>
  );
};

export { NavDropdown };
