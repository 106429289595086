'use client';
import { FieldValue, FieldValues, UseFormSetValue } from 'react-hook-form';

import { InfoOutlined } from '@mui/icons-material';
import clsx from 'clsx';

import { Tooltip } from '@/components/tooltip';

import * as S from './styles';

interface CheckboxProps {
  label?: string;
  description?: string;
  size?: 'sm' | 'md';
  radius?: 'rounded' | 'circle';
  isIndeterminate?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  classNameWrapper?: string;
  customLabel?: React.ReactNode;
  name?: string;
  errorMessage?: string;
  error?: boolean;
  checked?: boolean;
  value?: string;
  setValue?: UseFormSetValue<FieldValue<FieldValues>>;
  onChange?: (isChecked: boolean | string | undefined) => void;
  tooltip?: string;
}

const Checkbox = ({
  label,
  description,
  isIndeterminate,
  size = 'sm',
  radius = 'rounded',
  disabled = false,
  defaultChecked = false,
  classNameWrapper,
  customLabel,
  name,
  errorMessage,
  error,
  value,
  setValue,
  onChange,
  checked,
  tooltip = '',
}: CheckboxProps) => {
  const containerClassName = clsx(`size-${size}`, `radius-${radius}`);

  const handleCheckedChange = (isChecked: boolean | string) => {
    const saveValue = value ? value : isChecked;
    if (onChange) {
      onChange(saveValue);
    }

    if (setValue && name) {
      setValue(name, saveValue, {
        shouldValidate: true,
      });
    }
  };

  return (
    <S.Wrapper className={classNameWrapper}>
      <S.Container>
        <S.CheckboxRoot
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          className={containerClassName}
          id={name || label}
          onCheckedChange={handleCheckedChange}>
          <S.CheckboxIndicator
            data-testid="checkboxIndicator"
            className={containerClassName}>
            {isIndeterminate ? (
              <S.SvgIsIndeterminate data-testid="indeterminate" />
            ) : radius === 'circle' ? (
              <S.SvgCircle />
            ) : (
              <S.SvgChecked />
            )}
          </S.CheckboxIndicator>
        </S.CheckboxRoot>

        <S.TextCheckbox>
          <span>
            {customLabel ? (
              <S.Label
                htmlFor={name || label}
                className={containerClassName}
                data-disabled={disabled}>
                {customLabel}
              </S.Label>
            ) : (
              <S.Label
                htmlFor={name || label}
                className={containerClassName}
                data-disabled={disabled}>
                {label}
              </S.Label>
            )}
            {!!tooltip.length && (
              <Tooltip
                label={<InfoOutlined />}
                modal="true"
                contrast="low"
                description={tooltip}
              />
            )}
          </span>
          <S.Description
            className={containerClassName}
            data-disabled={disabled}>
            {description}
          </S.Description>
        </S.TextCheckbox>
      </S.Container>
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Wrapper>
  );
};

export { Checkbox };
