'use client';

import { FieldValue, FieldValues, UseFormSetValue } from 'react-hook-form';

import { Size } from '@/utils/enums';
import { SwitchProps as TSwitchProps } from '@radix-ui/react-switch';
import clsx from 'clsx';

import * as S from './styles';

type switchSize = Exclude<Size, 'lg'>;

type SwitchProps = {
  size?: switchSize;
  label?: string;
  name?: string;
  classNameWrapper?: string;
  setValue?: UseFormSetValue<FieldValue<FieldValues>>;
  onValueChange?: (value: boolean) => void;
  description?: string;
} & TSwitchProps;

const Switch = ({
  disabled = false,
  size = 'sm',
  label,
  description,
  name = 'switch',
  classNameWrapper,
  setValue,
  onValueChange,
  ...props
}: SwitchProps) => {
  const containerClassName = clsx(`size-${size}`);

  const handleChange = (value: boolean) => {
    if (setValue) {
      setValue(name, value);
    }

    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <S.Wrapper
      className={`${classNameWrapper} ${props.className}`}
      data-center={!!description}>
      <S.SwitchRoot
        {...props}
        className={containerClassName}
        disabled={disabled}
        id={name}
        name={name}
        onCheckedChange={handleChange}>
        <S.SwitchThumb className={containerClassName} />
      </S.SwitchRoot>
      <S.Content>
        {label && <S.Label htmlFor={name}>{label}</S.Label>}
        {description && (
          <S.Description htmlFor={name}>{description}</S.Description>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export { Switch };
