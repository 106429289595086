import styled, { css } from 'styled-components';

export const WrapperTable = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    width: 100%;
    outline: 1px solid ${theme.colors.gray[200]};

    &.rounded {
      border-radius: 0 0 ${theme.spacing['12']} ${theme.spacing['12']};
      outline: 0;
      overflow: hidden;
    }
  `};
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: max-content;
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    min-width: 100%;
    border-collapse: collapse;
  `};
`;

export const TableHeader = styled.thead`
  ${({ theme }) => css`
    background-color: ${theme.newColors.surfaceSecondarySurfaceSecondary};

    & > tr {
      border-bottom: 1px solid ${theme.colors.gray[200]};
    }
  `};
`;

export const TableBody = styled.tbody`
  > tr:last-child {
    border: none;
  }
`;

export const TableFooter = styled.tfoot`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[100]};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${theme.colors.gray[600]};
  `};
`;

export const TableRow = styled.tr`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.gray[200]};
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(229, 231, 235, 0.5);
    }
  `};
`;

export const TableHeaderCell = styled.th`
  ${({ theme }) => css`
    color: ${theme.colors.gray[600]};
    padding: ${theme.spacing['06']} ${theme.spacing['24']};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    text-align: left;
    font-weight: ${theme.typography.fontWeight.regular};
    svg {
      height: 20px;
      width: 20px;
    }
    &:has([role='checkbox']) {
      padding-right: 0;
    }
    &:last-child {
      & > button {
        padding: 4px;
        margin: 0 auto;
      }
    }
  `};
`;

export const TableCell = styled.td`
  ${({ theme }) => css`
    padding: ${theme.spacing[20]} ${theme.spacing['24']};
    vertical-align: middle;
    color: ${theme.colors.gray[600]};
    border-bottom: 1px solid ${theme.colors.gray[200]};
    border-top: 1px solid ${theme.colors.gray[200]};
    > div {
      display: flex;
      gap: ${theme.spacing['08']};
      align-items: center;
    }
    &:has([role='checkbox']) {
      padding-right: 0;
    }
    .special-table & {
      &:last-child {
        padding: ${theme.spacing[16]} ${theme.spacing[24]};
        margin: 0 1.875rem;
        border-left: 2px solid ${theme.colors.gray[200]};
        background-color: ${theme.newColors.surfacePrimarySurfacePrimary};
      }
    }
    .special-table & {
      &:last-child {
        background-color: ${theme.newColors.surfacePrimarySurfacePrimary};
        button {
          background-color: ${theme.newColors.surfacePrimarySurfacePrimary};
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &.medium {
      font-weight: ${theme.typography.fontWeight.medium};
      color: ${theme.colors.gray[900]};
    }

    &.semi-bold {
      font-weight: ${theme.typography.fontWeight.semiBold};
      color: ${theme.colors.gray[900]};
    }

    &.bold {
      font-weight: ${theme.typography.fontWeight.bold};
      color: ${theme.colors.gray[900]};
    }

    &.shrink {
      white-space: nowrap;
      width: 1px;
      text-align: center;
    }
    &.none {
      display: flex;
      padding: 0;
    }
    &.shorter {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `};
`;
