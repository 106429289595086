import styled, { css } from 'styled-components';

export const PaginationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing['08']};
    flex-direction: column;
    ${theme.breakpoints.tablet720} {
      flex-direction: row;
      padding-right: ${theme.spacing['24']};
    }
  `}
`;

export const FooterPagination = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing['08']};
    ${theme.breakpoints.tablet960} {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
  `}
`;

export const PaginationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${theme.spacing['24']};
    gap: ${theme.spacing['08']};
  `}
`;

export const WrapperPaginationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing['08']};
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${theme.spacing['40']};
    height: ${theme.spacing['40']};
    border: 1px solid ${theme.colors.gray['500']};
    color: ${theme.colors.gray['700']};
    font-weight: ${theme.typography.fontWeight.semiBold};
    background-color: ${theme.colors.white};

    &:disabled {
      background-color: ${theme.colors.white};
    }

    &:hover {
      background-color: ${theme.colors.gray['300']};
    }

    & > .firstOne {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: ${theme.spacing['10']} ${theme.spacing['16']};
      gap: ${theme.spacing['08']};
      svg {
        fill: ${theme.colors.gray['700']};
      }
    }
  `}
`;
