import Link from 'next/link';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled, { css } from 'styled-components';

import {
  enterFromRight,
  enterFromLeft,
  exitToRight,
  exitToLeft,
  scaleIn,
  scaleOut,
} from './animations';

export const NavigationMenuRoot = styled(NavigationMenu.Root)`
  position: relative;
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['08']};
    justify-content: center;
    list-style: none;
    font-weight: ${theme.typography.fontWeight.bold};
  `}
`;

export const NavigationMenuTrigger = styled(NavigationMenu.Trigger)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing['08']} ${theme.spacing[12]};
    gap: ${theme.spacing['08']};
    color: ${theme.colors.white};
    border-radius: ${theme.radius.xs};
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: ${theme.colors.white};
    }
    &.projectList {
      background-color: transparent;
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.white};
    }
  `}
`;

export const SvgDown = styled(KeyboardArrowDownIcon)`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    color: white;
    top: 1px;
    transition: transform 250ms ease;

    ${NavigationMenuTrigger}:hover & {
      color: white;
      &.projectList {
        color: ${theme.colors.white};
      }
    }

    ${NavigationMenuTrigger}[data-state='open'] & {
      transform: rotate(-180deg);
    }
  `}
`;

export const NavigationMenuContent = styled(NavigationMenu.Content)`
  ${({ theme }) => css`
    animation-duration: 250ms;
    animation-timing-function: ease;
    box-shadow: ${theme.shadows.md};
    border-radius: ${theme.radius.md};
    background-color: ${theme.colors.white};

    &[data-motion='from-start'] {
      animation-name: ${enterFromLeft};
    }

    &[data-motion='from-end'] {
      animation-name: ${enterFromRight};
    }

    &[data-motion='to-start'] {
      animation-name: ${exitToLeft};
    }

    &[data-motion='to-end'] {
      animation-name: ${exitToRight};
    }
  `}
`;

export const NavigationMenuViewport = styled(NavigationMenu.Viewport)`
  ${({ theme }) => css`
    position: relative;
    transform-origin: top center;
    margin-top: ${theme.spacing[16]};
    transition: width, height, 300ms ease;

    &[data-state='open'] {
      animation: ${scaleIn} 200ms ease;
      z-index: 3;
    }

    &[data-state='closed'] {
      animation: ${scaleOut} 200ms ease;
    }
  `}
`;

export const ViewportPosition = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &.viewPortUnset {
    justify-content: unset;
  }
`;

export const ListUl = styled.ul`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const ListLi = styled.li`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > a {
      align-items: flex-start;
      width: 100%;
    }

    &.error {
      > svg {
        color: ${theme.colors.error['500']};
      }

      ${ListDescription}, ${ListLink} {
        color: ${theme.colors.error['500']};
      }
    }

    > svg {
      color: ${theme.colors.gray[900]};
    }
  `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};

    &:first-child {
      border-top-left-radius: ${({ theme }) => theme.radius.md};
      border-top-right-radius: ${({ theme }) => theme.radius.md};
    }

    &:last-child {
      border-bottom-left-radius: ${({ theme }) => theme.radius.md};
      border-bottom-right-radius: ${({ theme }) => theme.radius.md};
    }
  }
`;

export const ListLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.gray[900]};
    font-weight: ${theme.typography.fontWeight.bold};
  `}
`;

export const ListDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
  `}
`;

export const LinkContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding: ${theme.spacing[12]} ${theme.spacing[32]};

    & > svg {
      margin-top: ${({ theme }) => theme.spacing['06']};
    }
  `}
`;

export const ContentWrapper = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacing[16]};
  `}
`;
