import Circle from '@mui/icons-material/Brightness1';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CheckboxRoot = styled(Checkbox.Root)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray[300]};
    display: flex;
    align-items: center;
    justify-content: center;

    &.size-sm {
      min-width: ${theme.spacing[16]};
      height: ${theme.spacing[16]};
      border-radius: 4px;
    }

    &.size-md {
      min-width: ${theme.spacing[20]};
      height: ${theme.spacing[20]};
      border-radius: 6px;
    }

    &.radius-circle {
      border-radius: ${theme.radius.circle};
    }

    &[data-disabled] {
      background-color: ${theme.colors.gray[100]};
      border-color: ${theme.colors.gray[200]};
      cursor: not-allowed;
    }

    &[data-state='checked']:not([data-disabled]) {
      background-color: ${theme.colors.accent[500]};
      border-color: ${theme.colors.accent[500]};
    }

    &:hover:not([data-disabled]) {
      border-color: ${theme.colors.accent[500]};
    }

    &:focus:not([data-disabled]) {
      box-shadow: 0 0 0 4px ${theme.colors.gray[100]};
      border-color: ${theme.colors.accent[500]};
      outline: none;
    }
  `}
`;

export const CheckboxIndicator = styled(Checkbox.Indicator)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }

    &.size-sm {
      width: ${theme.spacing[12]};
      height: ${theme.spacing[12]};
    }

    &.size-md {
      width: ${theme.spacing[16]};
      height: ${theme.spacing[16]};
    }
  `}
`;

export const SvgCircle = styled(Circle)`
  ${({ theme }) => css`
    color: ${theme.colors.white};

    svg {
      width: ${theme.spacing['08']};
      height: ${theme.spacing['08']};
    }

    &[data-disabled] {
      color: ${theme.colors.gray[200]};
    }
  `};
`;

export const SvgChecked = styled(DoneRoundedIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.white};

    &[data-disabled] {
      color: ${theme.colors.gray[200]};
    }
  `};
`;
export const SvgIsIndeterminate = styled(RemoveRoundedIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    &[data-disabled] {
      color: ${theme.colors.gray[200]};
    }
  `}
`;

export const TextCheckbox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > span {
      display: flex;
      align-items: center;
      gap: 6px;

      & > div > svg {
        color: ${theme.newColors.textSecondaryTextSecondary};
        width: 1rem;
        height 1rem;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray[900]};
    margin-left: ${theme.spacing['08']};
    font-weight: ${theme.typography.fontWeight.medium};

    &.size-sm {
      font-size: ${theme.typography.fontSize['14'].size};
    }

    &.size-md {
      font-size: ${theme.typography.fontSize['16'].size};
    }

    &[data-disabled='true'] {
      color: ${theme.colors.gray[300]};
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    margin-left: ${theme.spacing['08']};
    font-weight: ${theme.typography.fontWeight.regular};

    &.size-sm {
      font-size: ${theme.typography.fontSize['14'].size};
    }

    &.size-md {
      font-size: ${theme.typography.fontSize['16'].size};
    }

    &[data-disabled='true'] {
      color: ${theme.colors.gray[300]};
    }
  `}
`;
export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;
