import {
  ModalContent,
  ModalOverlay,
  ModalPortal,
  ModalRoot,
} from '@/components/modal';

import * as S from './styles';

type ModalOrdenateColumnProps = {
  openModal: boolean;
  handleOpenModal: () => void;
  openPosition?: S.Position;
  children?: React.ReactNode;
};

export const Modal = ({
  openModal,
  handleOpenModal,
  openPosition,
  children,
}: ModalOrdenateColumnProps) => {
  return (
    <ModalRoot open={openModal} onOpenChange={handleOpenModal}>
      <ModalPortal>
        <>
          <ModalOverlay data-testid="overlay" />
          <ModalContent openPosition={openPosition}>{children}</ModalContent>
        </>
      </ModalPortal>
    </ModalRoot>
  );
};
