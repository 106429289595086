'use client';
import { ReactNode, useState } from 'react';

import * as Select from '@radix-ui/react-select';
import clsx from 'clsx';

import { Checkbox } from '../check-box';
import * as S from './styles';

type Variations = 'default' | 'checkIcon';

interface SelectBoxesProps {
  label: string;
  icon: ReactNode;
  disabled?: boolean;
  variations?: Variations;
}

const SelectBoxes = ({
  variations = 'default',
  disabled = false,
  label,
  icon,
}: SelectBoxesProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const containerClassName = clsx(`variations-${variations}`);

  const handleToggleCheckbox = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <Select.Root disabled={disabled}>
      <S.BoxesTrigger
        asChild
        $isChecked={isChecked}
        data-testid="selectContainer"
        onClick={handleToggleCheckbox}
        className={containerClassName}
        disabled={disabled}>
        <div>
          {variations === 'checkIcon' && (
            <div className="checkbox">
              <Checkbox
                disabled={disabled}
                radius="circle"
                checked={isChecked}
              />
            </div>
          )}
          <div className="content">
            {icon}
            {label}
          </div>
        </div>
      </S.BoxesTrigger>
    </Select.Root>
  );
};

export { SelectBoxes };
