import { RequestParamsErrorResponseProps } from '@/utils/request';
import { QueryClient, QueryCache } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err) => {
      const error = err as RequestParamsErrorResponseProps;
      if (error && error?.code === 500) {
        return console.error(error);
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});
