'use client';

import React, { createContext, useContext, useState, ReactNode } from 'react';

import { StatusToast, Toast } from '@/components/toast';

interface ToastContextType {
  showToast: (title: string, description: string, status: StatusToast) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState<StatusToast>('info');

  const showToast = (
    title: string,
    description: string,
    status: StatusToast
  ) => {
    setTitle(title);
    setDescription(description);
    setStatus(status);
    setOpen(true);
  };

  const handleCloseToast = () => setOpen(false);

  const values = {
    showToast,
    handleCloseToast,
  };

  return (
    <ToastContext.Provider value={values}>
      {children}
      <Toast
        title={title}
        description={description}
        status={status}
        position="top-right"
        contrast="high"
        openToast={open}
        handleCloseToast={handleCloseToast}
      />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) throw new Error('useToast must be used within a ToastProvider');
  return context;
};
