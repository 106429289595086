'use client';

import CloseIcon from '@mui/icons-material/Close';
import { DialogCloseProps } from '@radix-ui/react-dialog';

import { Button } from '@/components';

import * as S from './styles';

type ModalCloseButtonProps = {
  children?: React.ReactNode;
} & DialogCloseProps;

export const ModalCloseButton = ({
  children,
  ...props
}: ModalCloseButtonProps) => {
  return (
    <S.CloseComponent {...props}>
      {children ? (
        children
      ) : (
        <Button
          color="white"
          className="iconButton"
          type="button"
          size="sm"
          icon={<CloseIcon />}
        />
      )}
    </S.CloseComponent>
  );
};
