import Link from 'next/link';

import styled, { DefaultTheme, css } from 'styled-components';

const commonStyles = (theme: DefaultTheme) => css`
  color: inherit;
  font-family: ${theme.typography.fontFamily.inter};
  font-weight: ${theme.typography.fontWeight.bold};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.accent['500']};
  }

  &:focus {
    color: ${theme.colors.accent['500']};
    background-color: ${theme.colors.accent['100']};
  }

  &:disabled {
    color: ${theme.colors.gray['300']};
  }

  &.size-sm {
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
  }

  &.size-md {
    font-size: ${theme.typography.fontSize['16'].size};
    line-height: ${theme.typography.fontSize['16'].lineHeight};
  }
`;

export const LinkComponent = styled(Link)<{ underline?: boolean | string }>`
  ${({ theme, underline }) => css`
    ${commonStyles(theme)}

    display: flex;
    align-items: center;
    text-decoration-line: ${underline ? 'underline' : 'none'};

    &:visited {
      cursor: pointer;
      color: color: inherit;
    }
  `}
`;

export const ButtonComponent = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;

  ${({ theme }) => commonStyles(theme)}
`;
