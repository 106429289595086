import styled, { css } from 'styled-components';

export const FixedText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    margin-left: 15px;
    margin-right: -10px;
    font-size: ${theme.typography.fontSize['12'].size};
    font-weight: ${theme.typography.fontWeight.medium};
  `}
`;

export const MultInput = styled.div`
  ${({ theme }) => css`
    & > .rti--container {
      display: flex !important;
      width: 100%;
      height: 100%;
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      justify-content: flex-start;
      align-items: center;
      border: none !important;
      box-shadow: none !important;
      padding-top: ${theme.spacing['06']};

      & > input {
        display: flex;
        flex: 1;
        border: none;
      }
    }

    & > .rti--container:focus,
    & > .rti--container:focus-within {
      border: none !important;
      box-shadow: none !important;
    }
  `}
`;

export const InputContainer = styled.div<{ hasFocus: boolean }>`
  ${({ theme, hasFocus = true }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: ${theme.radius.xs};
    color: ${theme.colors.gray[400]};
    width: 100%;
    overflow: hidden;
    background-color: white;

    &.error {
      border-color: ${theme.colors.error[700]};
    }

    &:focus-within {
      border: ${hasFocus ? '2px' : 'none'} solid ${theme.colors.gray[700]};
    }

    &:focus-within.error {
      border: 2px solid ${theme.colors.error[700]};
    }

    &.size-sm {
      height: ${theme.spacing['40']};
    }
    &.size-md {
      height: 44px;
    }
    &.size-lg {
      height: ${theme.spacing['48']};
    }
    &.border-left {
      border-radius: 0 ${theme.radius.xs} ${theme.radius.xs} 0;
    }
    &.border-right {
      border-radius: ${theme.radius.xs} 0 0 ${theme.radius.xs};
    }
    &.border-none {
      border-radius: ${theme.radius.xs};
    }

    &.disabled {
      ${({ theme }) => css`
        color: ${theme.colors.gray[300]};
        background-color: ${theme.colors.gray[100]};
        border-color: ${theme.colors.gray[200]};
        cursor: not-allowed;
      `}
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${theme.spacing['08']};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${theme.colors.gray[900]};
    align-items: center;

    & > span {
      display: flex;
      align-items: center;
      gap: 6px;

      & > div > svg {
        color: ${theme.newColors.textSecondaryTextSecondary};
        width: 1rem;
        height 1rem;
      }
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding-left: ${theme.spacing['16']};
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      color: black !important;
      -webkit-text-fill-color: black !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-background-clip: text !important;
      background-clip: text !important;
    }
    &.disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      font-size: ${theme.typography.fontSize['14'].size};
      font-weight: ${theme.typography.fontWeight.regular};
      color: ${theme.colors.gray[400]};
    }
  `}
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;

export const DescriptionMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[400]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;

export const ButtonPassword = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    border: none;
    margin: ${theme.spacing['08']};
    background-color: transparent;
    color: ${theme.colors.gray[400]};
  `}
`;

export const SuffixLabel = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.newColors.surfaceSecondarySurfaceSecondary};
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacing['16']};
    gap: ${theme.spacing['08']};
    height: 100%;
    color: ${theme.newColors.textPrimaryTextPrimaryMuted};
    font-weight: ${theme.typography.fontWeight.regular};
    border: none;

    &.noBackground {
      background: none;
      color: unset;
    }

    &.disabled {
      ${({ theme }) => css`
        color: ${theme.colors.gray[400]};
        background-color: ${theme.colors.gray[200]};
        cursor: not-allowed;
      `}
    }
  `}
`;

export const PrefixLabel = styled.button<{ $isImage: boolean }>`
  ${({ theme, $isImage }) => css`
    display: flex;
    background-color: ${theme.colors.gray[100]};
    align-items: center;
    padding: ${$isImage
      ? `0 0 0 ${theme.spacing['16']} `
      : `0 ${theme.spacing['16']}`};
    gap: ${theme.spacing['08']};
    height: 100%;
    border: none;
    color: ${theme.colors.gray[600]};
    font-weight: ${theme.typography.fontWeight.regular};

    &.noBackground {
      background: none;
      color: unset;
    }

    &.disabled {
      ${({ theme }) => css`
        color: ${theme.colors.gray[400]};
        background-color: ${theme.colors.gray[200]};
        cursor: not-allowed;
      `}
    }
  `}
`;

export const OptionalLabel = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize['12'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.typography.fontSize['12'].lineHeight};
    color: ${theme.colors.gray[400]};
  `}
`;
