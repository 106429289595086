'use client';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Button, Select } from '@/components';

import * as S from './styles';

type PaginationProps = {
  limit?: number;
  total?: number;
  offset?: number;
  className?: string;
  setOffset?: (offset: number) => void;
  setLimit?: (limit: number) => void;
  setCurrentPage?: (page: number) => void;
};

export function Pagination({
  limit = 10,
  total = 0,
  offset = 0,
  className,
  setOffset,
  setLimit,
  setCurrentPage,
}: PaginationProps) {
  const [current, setCurrent] = useState(Math.floor(offset / limit) + 1);
  const pages = Math.ceil(total / limit);

  useEffect(() => {
    setCurrent(Math.floor(offset / limit) + 1);
  }, [offset, limit]);

  function onPageChange(page: number) {
    if (setOffset) {
      const newOffset = (page - 1) * limit;
      setOffset(newOffset);
      setCurrent(page);
      if (setCurrentPage) {
        setCurrentPage(page);
      }
    }
  }

  const { control, setValue } = useForm({
    defaultValues: { valueSelectTable: limit.toString() },
  });

  useEffect(() => {
    setValue('valueSelectTable', limit.toString());
  }, [limit, setValue]);

  return (
    <S.FooterPagination className={className}>
      <S.PaginationContainer>
        {current} -
        <Select
          control={control}
          onChange={(value) => {
            const newLimit = Number(value);
            if (setLimit) {
              setLimit(newLimit);
            }
            if (setOffset) {
              setOffset(0);
            }
            setCurrent(1);
            if (setCurrentPage) {
              setCurrentPage(1);
            }
          }}
          items={[
            { label: '10', value: '10' },
            { label: '20', value: '20' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
          name="valueSelectTable"
        />
        de {total} resultados
      </S.PaginationContainer>
      <S.PaginationButtons>
        {current} de {pages} páginas
        <S.WrapperPaginationButtons>
          <Button
            icon={<ChevronLeftIcon />}
            color="white"
            type="button"
            disable={current === 1}
            onClick={() => onPageChange(current - 1)}>
            Anterior
          </Button>
          <Button
            icon={<ChevronRightIcon />}
            iconPosition="right"
            color="white"
            type="button"
            disable={current === pages}
            onClick={() => onPageChange(current + 1)}>
            Próximo
          </Button>
        </S.WrapperPaginationButtons>
      </S.PaginationButtons>
    </S.FooterPagination>
  );
}
