import Link from 'next/link';

import styled, { css } from 'styled-components';

export const LinkButton = styled(Link)`
  && {
    ${({ theme }) => css`
      & > .image {
        color: ${theme.colors.error['500']};
        width: ${theme.spacing['24']};
        height: ${theme.spacing['24']};
      }
    `}
  }
`;

export const Wrapper = styled.div`
  && {
    ${({ theme }) => css`
      position: fixed;
      bottom: 36px;
      right: 10vw;

      background-color: #ffe2e0;
      width: 44px;
      height: 44px;
      border-radius: ${theme.radius.md};

      &:hover {
        background-color: #ffdedb;
        cursor: pointer;

        & > svg {
          color: ${theme.colors.error['600']};
        }
      }

      & > div {
        width: 100%;
        height: 100%;
        display: flex;

        align-items: center;
        justify-content: center;
      }
    `}
  }
`;
