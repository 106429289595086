import * as reactAvatar from '@radix-ui/react-avatar';
import styled, { css } from 'styled-components';

export const StyledAvatar = styled(reactAvatar.Root)`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    vertical-align: middle;
    border-radius: ${theme.radius.circle};
    overflow: hidden;
    user-select: none;
    &.radius-xs {
      border-radius: ${theme.radius.xs};
    }
    &.size-lg .Notification {
      font-size: ${theme.typography.fontSize[12].size};
      width: ${theme.spacing['16']};
      height: ${theme.spacing['16']};
    }
    &.size-sm {
      width: ${theme.spacing['24']};
      height: ${theme.spacing['24']};
      font-size: ${theme.typography.fontSize[14].size};
      line-height: ${theme.typography.fontSize[14].lineHeight};
    }
    &.size-md {
      width: ${theme.spacing['32']};
      height: ${theme.spacing['32']};
      font-size: ${theme.typography.fontSize[14].size};
      line-height: ${theme.typography.fontSize[14].lineHeight};
    }
    &.size-lg {
      width: ${theme.spacing['40']};
      height: ${theme.spacing['40']};
      font-size: ${theme.typography.fontSize[14].size};
      line-height: ${theme.typography.fontSize[14].lineHeight};
    }
    &.size-xl {
      width: ${theme.spacing['48']};
      height: ${theme.spacing['48']};
      font-size: ${theme.typography.fontSize[14].size};
      line-height: ${theme.typography.fontSize[14].lineHeight};
    }
    .AvatarImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
    .AvatarFallback {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors.gray[300]};
      font-family: ${theme.typography.fontFamily.inter};
      font-weight: ${theme.typography.fontWeight.medium};
      &.background-secondary {
        background-color: ${theme.colors.secondary[100]};
      }
    }
  `}
`;
