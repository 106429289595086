'use client';

import { FC, ReactNode } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Button } from '@/components';

import * as S from './styles';

export type DropDownMenuProps = {
  items: Array<{
    text: string;
    value: string | number;
    icon?: ReactNode;
    onClick?: (values?: string) => void;
    show?: boolean;
    className?: string;
  }>;
  text: string | ReactNode;
  subtext?: string;
  iconLeft?: boolean;
  account?: string;
  category:
    | 'danger'
    | 'multi-line'
    | 'default'
    | 'button'
    | 'account'
    | 'category'
    | 'divider'
    | 'icon';
  itemValue?: string;
};

const DropDownMenu: FC<DropDownMenuProps> = ({
  items,
  text,
  subtext,
  account,
  category = 'default',
  itemValue,
}) => {
  return (
    <DropdownMenu.Root>
      <S.DropdownTrigger asChild>
        <S.Container category={category}>
          {category === 'account' && account && (
            <>
              <S.AccountImage src={account} alt="Imagem da conta" />
              <S.TextContainer>
                <S.Text category={category}>{text}</S.Text>
                {subtext && (
                  <S.SubText category={category}>{subtext}</S.SubText>
                )}
              </S.TextContainer>
            </>
          )}

          {category === 'multi-line' && (
            <div>
              <S.Text category={category}>{text}</S.Text>
              <S.SubText category={category}>{subtext}</S.SubText>
            </div>
          )}
          {category === 'button' && (
            <Button size="md" color="primary">
              {text}
            </Button>
          )}

          {category === 'default' && (
            <S.Text category={category}>{text}</S.Text>
          )}

          {category === 'category' && (
            <S.Text category={category}>{text}</S.Text>
          )}

          {category === 'danger' && <S.Text category={category}>{text}</S.Text>}

          {category === 'divider' && <S.DividerLine />}

          {category === 'icon' && text}
        </S.Container>
      </S.DropdownTrigger>

      <S.DropdownContent>
        {items.map((item, index) => (
          <S.ListItem
            key={index}
            className={item.className}
            onClick={() => item?.onClick && item?.onClick(itemValue)}>
            <>
              {item.icon && <span data-testid="icon">{item.icon}</span>}
              <span>{item.text}</span>
            </>
          </S.ListItem>
        ))}
      </S.DropdownContent>
    </DropdownMenu.Root>
  );
};

export { DropDownMenu };
