import { useState } from 'react';

import {
  ListProjectsByUserProps,
  getListProjectsByUser,
} from '@/services/project/get-list-projects-by-user';
import { mapperListProjectsByUser } from '@/utils/mappers/project/mapper-get-project-by-user';
import { objectIsEmpty } from '@/utils/validate/object-is-empty';
import { useQuery } from '@tanstack/react-query';

export const useListProjectsByUser = (
  userId: string,
  organizationId: string,
  initialData?: [] | ListProjectsByUserProps[],
  projectName?: string
) => {
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['projects-by-userId', userId, organizationId, projectName],
    queryFn: async () => {
      try {
        setLoading(true);
        const returnList = await getListProjectsByUser(
          userId,
          organizationId,
          projectName
        );

        const returnListMapper = mapperListProjectsByUser(
          objectIsEmpty(returnList)
            ? []
            : (returnList as ListProjectsByUserProps[])
        );
        setLoading(false);
        return returnListMapper;
      } catch (error) {
        setLoading(false);
        return [];
      }
    },
    initialData: initialData || [],
  });

  return { data: data || [], isLoading, loading };
};
