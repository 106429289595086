import { useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';

import { calculateDaysBetween } from '@/utils/date';
import { CalendarMonth, CloseOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

import 'react-day-picker/dist/style.css';
import { Button } from '../button';
import * as S from './styles';

type CalendarProps = {
  label?: string;
  name?: string;
  singleSelect?: boolean;
  onFilter?: (date: DateRange) => void;
  align?: 'start' | 'center' | 'end';
  emptySelection?: boolean;
  sevenDaysRange?: boolean;
  customRange?: {
    from: Date;
    to: Date;
  };
};

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function subDays(date: Date, days: number) {
  return addDays(date, -days);
}

function format(date: Date) {
  if (!date) return '';
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  return formatter.format(date).replace(/\./g, '');
}

export function Calendar({
  singleSelect = false,
  label,
  name,
  onFilter,
  align = 'center',
  customRange,
}: CalendarProps) {
  const currentDate = new Date();

  const customRangeFunction: DateRange = {
    from: customRange?.from,
    to: customRange?.to,
  };

  const [range, setRange] = useState<DateRange | undefined>(customRange);

  const diffBetweenDate = calculateDaysBetween(
    customRange?.from as never,
    customRange?.to as never
  );

  const [openPopover, setOpenPopover] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<number | undefined>(
    diffBetweenDate - 1
  );

  const handlePeriodSelection = (periodInDays: number) => {
    setSelectedPeriod(periodInDays);
    const fromDate = subDays(currentDate, periodInDays);
    setRange({ from: fromDate, to: currentDate });

    if (periodInDays <= 1) setRange({ from: fromDate, to: fromDate });
  };

  const handleDateSelect = (date: Date) => {
    if (!range) {
      setRange({ from: date, to: date });
    }

    if (range?.from && range?.to) {
      setRange({ from: date, to: undefined });
    }

    if (range?.from && !range?.to) {
      if (date > range.from) {
        setRange({ from: range.from, to: date });
      } else {
        setRange({ from: date, to: range.from });
      }
    }

    if (!range?.from && !range?.to) {
      setRange({ from: date, to: undefined });
    }

    if (!range?.from && range?.to) {
      setRange({ from: date, to: range.to });
    }
  };

  const handleOpenPopover = () => {
    setOpenPopover(false);
  };

  const handleFilter = () => {
    if (range && onFilter) {
      onFilter(range);
    }
    handleOpenPopover();
  };
  const handleClear = () => {
    setRange(undefined);
    onFilter && onFilter({ from: undefined, to: undefined });
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.tablet960);

  useEffect(() => {
    if (customRangeFunction?.from) {
      const updatedDate = new Date(customRangeFunction.from);
      updatedDate.setDate(updatedDate.getDate() + 1);
      customRangeFunction.from = updatedDate;
    }
    setRange(customRangeFunction);
  }, [customRange]);

  useEffect(() => {
    setSelectedPeriod(diffBetweenDate - 1);
  }, [customRange, diffBetweenDate]);

  return (
    <S.Main>
      <S.Popover
        open={openPopover}
        onOpenChange={(newOpenState) => {
          handleFilter();
          setOpenPopover(newOpenState);
        }}>
        <S.ButtonContainer>
          <S.LabelContainer>
            <S.PopoverTrigger asChild>
              <S.Label htmlFor={name} className={!label ? 'hidden' : undefined}>
                {label}
              </S.Label>
            </S.PopoverTrigger>

            {range?.from ? (
              <button onClick={handleClear}>
                limpar
                <CloseOutlined fontSize="inherit" />
              </button>
            ) : null}
          </S.LabelContainer>

          <S.ButtonWrapper
            color="white"
            type="button"
            onClick={() => setOpenPopover(!openPopover)}>
            {range?.from ? (
              range.to ? (
                <>
                  {format(range.from)} - {format(range.to)}
                </>
              ) : (
                format(range.from)
              )
            ) : (
              <S.ButtonText>Selecione um período</S.ButtonText>
            )}
            <CalendarMonth />
          </S.ButtonWrapper>
        </S.ButtonContainer>

        <S.PopoverPortal>
          <S.PopoverContent
            side="bottom"
            sideOffset={20}
            alignOffset={isDesktop ? 0 : -24}
            align={align}
            avoidCollisions={isDesktop}>
            <S.Wrapper>
              <S.WrapperContent>
                {isDesktop && (
                  <S.WrapperPeriod className="periodWrapper">
                    <S.WrapperPeriodIntern>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 0 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(0)}>
                        Hoje
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 1 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(1)}>
                        Ontem
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 6 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(6)}>
                        Últimos 7 dias
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 13 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(13)}>
                        Últimos 14 dias
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 29 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(29)}>
                        Últimos 30 dias
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 89 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(89)}>
                        Últimos três meses
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 179 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(179)}>
                        Últimos seis meses
                      </Button>
                      <Button
                        className="periodButton"
                        color={selectedPeriod === 364 ? 'primary' : 'white'}
                        onClick={() => handlePeriodSelection(364)}>
                        Último ano
                      </Button>
                    </S.WrapperPeriodIntern>
                  </S.WrapperPeriod>
                )}
                <S.FooterButton>
                  <div>
                    <DayPicker
                      className="dataContent"
                      id="calendar"
                      mode="range"
                      initialFocus
                      numberOfMonths={!isDesktop ? 1 : 2}
                      pagedNavigation
                      weekStartsOn={1}
                      showOutsideDays
                      fixedWeeks
                      selected={{ from: range?.from, to: range?.to }}
                      onDayClick={handleDateSelect}
                      onSelect={(newRange) => setRange(newRange as DateRange)}
                      modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today',
                      }}
                      modifiersStyles={{
                        disabled: { fontSize: '75%' },
                        range_middle: {
                          backgroundColor: '#F0F1FF',
                          width: 40,
                          height: 36,
                        },
                        range_start: {
                          backgroundColor: '#6938ef',
                          color: '#FFF',
                          borderRadius: 50,
                          width: 36,
                          height: 36,
                        },
                        range_end: {
                          backgroundColor: '#6938ef',
                          color: '#FFF',
                          borderRadius: 50,
                          width: 36,
                          height: 36,
                        },
                        month: {
                          color: 'blue',
                        },
                        head_cell: { color: 'purple' },
                        button_nav: { backgroundColor: 'green' },
                      }}
                    />
                  </div>

                  <S.Footer>
                    {isDesktop && (
                      <S.ButtonRange>
                        <Button type="secondary" color="white">
                          {range?.from ? (
                            format(range.from)
                          ) : (
                            <span>Selecione uma data</span>
                          )}
                        </Button>
                        {!singleSelect && (
                          <>
                            <span> - </span>
                            <Button type="secondary" color="white">
                              {range?.to ? (
                                format(range.to)
                              ) : (
                                <span>Selecione uma data</span>
                              )}
                            </Button>
                          </>
                        )}
                      </S.ButtonRange>
                    )}
                    <div className="Button-apply">
                      <Button
                        className="periodButton"
                        color={'white'}
                        onClick={handleOpenPopover}>
                        Cancelar
                      </Button>
                      <Button
                        className="periodButton"
                        type="button"
                        color={'accent'}
                        onClick={handleFilter}>
                        Aplicar
                      </Button>
                    </div>
                  </S.Footer>
                </S.FooterButton>
              </S.WrapperContent>
            </S.Wrapper>
          </S.PopoverContent>
        </S.PopoverPortal>
      </S.Popover>
    </S.Main>
  );
}
