'use client';

import { useState } from 'react';

import { useServerInsertedHTML } from 'next/navigation';

import { GlobalProvider } from '@/hooks/useGlobal';
import { AssinyThemeProvider } from '@/providers/assiny-theme-provider';
import ReactQueryContext from '@/providers/react-query';
import { ToastProvider } from '@/providers/toast-context';
import ToastContainerWrapper from '@/providers/toast-provider';
import * as S from '@/styles/globals';
import GlobalStyle from '@/utils/themes/global';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';

function StyledComponentsRegistry({ children }: { children: React.ReactNode }) {
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return styles;
  });

  if (typeof window !== 'undefined') return <>{children}</>;

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      {children}
    </StyleSheetManager>
  );
}

export default function WrapperLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  return (
    <StyledComponentsRegistry>
      <AssinyThemeProvider>
        <html lang="pt-BR">
          <GlobalStyle />
          <S.Body className={className}>
            <ToastProvider>
              <ReactQueryContext>
                <GlobalProvider>{children}</GlobalProvider>
              </ReactQueryContext>
              <ToastContainerWrapper />
            </ToastProvider>
          </S.Body>
        </html>
      </AssinyThemeProvider>
    </StyledComponentsRegistry>
  );
}
