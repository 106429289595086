'use client';

import { ReactNode, CSSProperties, forwardRef } from 'react';

type Contrast = 'high' | 'low';
import { Size } from '@/utils/enums';
import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import * as S from './styles';

interface AccordionProps {
  label: string;
  description: ReactNode;
  contrast?: Contrast;
  size?: Size;
  disabled?: boolean;
  defaultOpen?: boolean;
  style?: CSSProperties;
}

const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(function AccordionTrigger({ children, ...props }, forwardedRef) {
  return (
    <S.AccordionHeader>
      <S.AccordionTrigger ref={forwardedRef} {...props}>
        {children}
      </S.AccordionTrigger>
    </S.AccordionHeader>
  );
});

const AccordionContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function AccordionContent({ children, ...props }, forwardedRef) {
  return (
    <S.AccordionContent ref={forwardedRef} {...props}>
      {children}
    </S.AccordionContent>
  );
});

const AccordionComponent = ({
  label,
  description,
  style,
  contrast = 'low',
  size = 'sm',
  disabled = false,
  defaultOpen = false,
}: AccordionProps) => {
  const commonClassName = clsx(`size-${size}`, `contrast-${contrast}`);

  const mergedStyles: CSSProperties = { ...style };

  const defaultOpenInString = defaultOpen ? 'item-1' : undefined;

  return (
    <Accordion.Root
      collapsible
      type="single"
      style={{ width: '100%' }}
      defaultValue={defaultOpenInString}>
      <S.AccordionItem
        data-disabled={disabled}
        value="item-1"
        style={mergedStyles}
        data-testid="accordion-item">
        <AccordionTrigger
          className={commonClassName}
          data-disabled={disabled}
          data-testid="accordion-trigger">
          {label}
          <S.SvgArrow />
        </AccordionTrigger>
        <AccordionContent className={commonClassName}>
          {description}
        </AccordionContent>
      </S.AccordionItem>
    </Accordion.Root>
  );
};

export { AccordionComponent };
