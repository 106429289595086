import { ReactNode } from 'react';

import theme from '@/utils/themes/assiny';
import { ThemeProvider } from 'styled-components';

export type AssinyThemeProviderProps = {
  children: ReactNode;
};

export const AssinyThemeProvider = ({ children }: AssinyThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
